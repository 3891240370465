import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';

var bnrimg = require('./../../images/scalp-micropigmentation-west-island-montreal.png');
var bgimg = require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me-4.png');

class ProjectDetail extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="Alopecia Hair Solutions - SMP (Scalp Micropigmentation)" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 tm-work-detail-wrap">
                        <div className="container">
                            <div className="project-detail-outer bg-top-left bg-parallax bg-center" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(' + bgimg.default + ')' }}>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 project-detail-pic" />
                                    <div className="col-md-6 col-sm-12 project-detail-containt bg-black square_shape3">
                                        <div className="p-lr20 p-tb80">
                                            <div className="bg-white p-lr30 p-tb50 text-black">
                                                <h2 className="m-t0"><span className="font-34 text-uppercase">Alopecia Solutions with SMP</span></h2>
                                                <p>In the fight against alopecia, Scalp MicroPigmentation offers a formidable solution. It not only conceals hair loss but also helps you regain confidence and feel better about your appearance. Say goodbye to the worries of alopecia and embrace the transformation that SMP can bring!

SMP is suitable for all skin types and colors. The pigmentation used can be matched to your natural hair color and skin tone, ensuring the result looks as natural as possible.</p>
                                                <div className="product-block">
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-6 m-b30">
                                                            <h5 className="text-uppercase">TREATMENT DATE</h5>
                                                            <p>To be scheduled</p>
                                                        </div>
                                                        <div className="col-md-6 col-sm-6 m-b30">
                                                            <h5 className="text-uppercase">For</h5>
                                                            <p>Individual seeking a solution for alopecia</p>
                                                        </div>
                                                        <div className="col-md-6 col-sm-6 m-b30">
                                                            <h5 className="text-uppercase">Project type</h5>
                                                            <p>Alopecia Treatment, SMP (Scalp MicroPigmentation) Procedure</p>
                                                        </div>
                                                        <div className="col-md-6 col-sm-6 m-b30">
                                                            <h5 className="text-uppercase">SMP SPECIALIST</h5>
                                                            <p>Pierre Castro</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-t0">
                                                    <ul className="social-icons social-square social-darkest m-b0">
                                                        <li><a target="_blank" href="https://www.facebook.com/gomicrooSMP" className="fa fa-facebook" /></li>
                                                        <li><a target="_blank"
                                                         href="https://www.instagram.com/scalpgomicro.smp/" className="fa fa-instagram" /></li>
                                                         <li><a target="_blank"
                                                         href="https://t.snapchat.com/EcjawztZ" className="fa fa-snapchat" /></li>
                                                
                                                    </ul>
                                                    <br />
                                                    <a href="tel:+14382285131" className="btn-half site-button button-lg m-b15">
                                                        <span>Call Us</span>
                                                        <em />
                                                    </a> 

                                                     
                                                    <a href="mailto:info@scalpgomicro.ca" className="btn-half site-button button-lg m-b15">
                                                    <span>Email Us</span>
                                                    <em />
                                                </a>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectDetail;