import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import RelatedProjects from './../Segments/RelatedProjects';
import BlogComments from './../Segments/BlogComments';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/micropigmentation-capillaire-calvitie-montreal.jpg');

const images = [
    { image: require('./../../images/Home/baldness-1.jpg') },
    { image: require('./../../images/Home/baldness.jpg') },
    { image: require('./../../images/Home/baldness-2.jpg') }
]

class PostGallery extends React.Component {
    render() {
        const options = {
            loop:true,
            margin:30,
            nav:true,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
              0:{
                items:1
              },
              480:{
                items:1
              },            
              767:{
                items:1
              },
              1000:{
                items:1
              }
            }
          };
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="How to Camouflage Baldness: An Insight Into Scalp Micropigmentation" bgimage={bnrimg.default}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                <div className="blog-post blog-lg date-style-1 text-black">
                                    <div className="wt-post-media">
                                        {/*Fade slider*/}
                                        <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                <div className="aon-thum-bx">
                                                    <img src={item.image.default} alt=""/>
                                                </div>
                                                </div>

                                            ))}

                                            </OwlCarousel>
                                        
                                        {/*fade slider END*/}
                                    </div>
                                    <div className="wt-post-info p-t30 bg-white">
                                        <div className="wt-post-title ">
                                            <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">How to Camouflage Baldness: An Insight Into Scalp Micropigmentation</span></h2>
                                        </div>
                                        <div className="wt-post-meta">
                                            <ul>
                                                <li className="post-date"> <strong>04 Dec</strong> <span> 2022</span> </li>
                                                <li className="post-comment">5 Comments </li>
                                            </ul>
                                        </div>
                                        <div className="wt-post-text">
                                            <p>Baldness, also known as "calvitie" in French, can be a significant concern for many individuals. It can lead to a lack of self-confidence and anxiety over one's appearance. But worry no more; an innovative method is changing the game for baldness treatment - Scalp Micropigmentation (SMP), a groundbreaking technique to camouflage baldness effectively.</p>

                                          <h3>  Understanding Baldness</h3>

<p>Before diving into the solution, let's understand the problem. Baldness, or hair loss, can occur due to various reasons such as genetics, aging, hormonal imbalance, or certain medical conditions. It often progresses gradually in predictable patterns, like a receding hairline or bald spots.</p>

<h3>Introduction to Scalp Micropigmentation</h3>

<p>Scalp Micropigmentation, also known as SMP, is a non-invasive treatment that uses microneedles to deposit pigment into the scalp. The result is the appearance of tiny hair follicles that help thicken and restore the look of fuller hair. SMP is a highly effective solution to 'camoufler calvitie' or camouflage baldness.</p>

<h3>The Process of SMP</h3>

<p>During SMP, a skilled practitioner uses a machine equipped with a microneedle to apply layers of pigment to the scalp. The practitioner applies tiny dots of natural pigment into the scalp's dermal layer, mirroring the natural appearance of real hair follicles. The color of the pigment, its depth, and the angle of application are customized to each client, ensuring a realistic, natural-looking result.</p>

<h3>Why Choose SMP to Camouflage Baldness?</h3>

<p>Here are some compelling reasons to consider SMP as a method to camouflage baldness:</p>

<ul><li>
Aesthetically Pleasing: SMP provides a natural-looking alternative to other hair restoration methods. It creates a realistic illusion of a fuller head of hair, effectively camouflaging baldness.</li>

<li>Non-invasive Procedure: Unlike hair transplants that require surgery, SMP is a non-invasive method, making it a safer and less painful option.</li>

<li>Durable: The results of SMP can last for several years, depending on skin type and lifestyle. Even as it begins to fade, a quick touch-up session can bring back the fresh, realistic look.</li>

<li>Low Maintenance: After getting an SMP treatment, there's no need for expensive hair products or medicines.</li>

<li>Boosts Confidence: By restoring the appearance of a fuller head of hair, SMP significantly enhances self-esteem and confidence.</li>
</ul>

<h3>Conclusion: Embrace Confidence with SMP</h3>

<p>Baldness should not be a cause of distress anymore. Scalp Micropigmentation is a proven method to 'camoufler calvitie', providing a safe, effective, and natural-looking solution to hair loss. It's about time to say goodbye to constant worries about your hair and say hello to a renewed sense of confidence. Contact a reputable SMP practitioner today to discuss your options and begin your journey towards a more confident you.</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-8 col-sm-6">
                                                <div className="widget bg-white  widget_tag_cloud">
                                                    <div className="tagcloud">
                                                    <NavLink to={"/aboutus"}>Scalp Micropigmentation</NavLink>
                                                    <NavLink to={"/aboutus"}>Hair Restoration</NavLink>
                                                    <NavLink to={"/aboutus"}>Non-surgical Treatment</NavLink>
                                                    <NavLink to={"/aboutus"}>Hair Loss Solution</NavLink>
                                                    <NavLink to={"/aboutus"}>SMP Procedure</NavLink>
                                                    <NavLink to={"/aboutus"}>Cost-effective</NavLink>
                                                    <NavLink to={"/aboutus"}>Baldness Solution</NavLink>
                                                    <NavLink to={"/aboutus"}>Hairline Enhancement</NavLink>
                                                    <NavLink to={"/aboutus"}>Self-Esteem Boost</NavLink>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="wt-box">
                                                    <div className="row  p-lr15">
                                                        <h4 className="tagcloud text-uppercase">Share this Post:</h4>
                                                        <div className="widget_social_inks">
                                                            <ul className="social-icons social-md social-square social-dark m-b0">
                                                                <li><a target="_blank" href="https://www.facebook.com" className="fa fa-facebook" /></li>
                                                                <li><a target="_blank" href="https://twitter.com" className="fa fa-twitter" /></li>
                                                                <li><a target="_blank" href="https://in.linkedin.com" className="fa fa-linkedin" /></li>
                                                                <li><a target="_blank" href="https://rss.com" className="fa fa-rss" /></li>
                                                                <li><a target="_blank" href="https://www.youtube.com/" className="fa fa-youtube" /></li>
                                                                <li><a target="_blank" href="https://www.instagram.com/" className="fa fa-instagram" /></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="wt-divider divider-3px bg-gray-dark"><i className="icon-dot c-square" /></div>
                                <RelatedProjects/>
                                 {/*<BlogComments/>*/}
                                
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default PostGallery;