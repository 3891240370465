import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';

class Slider3 extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <>
            <div className="slider-desktop">
    <div className="video-wrapper">
        <video className="video-element" controls autoPlay muted loop>
            <source src={require('./../../videos/scalp-micropigmentation-capillaire-montreal.mov').default} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="overlay-text">
            {/* Add the header, list, and buttons as per the design */}
            <h1 >MICROPIGMENTATION CAPILLAIRE</h1>
            <h2>Clinique Capillaire Go Micro Scalp SMP</h2>
            <ul>
                <li>Micropigmentation capillaire du cuir chevelu</li>
                <li>Micropigmentation au Barbe</li>
                <li>Consultation à domicile</li>
                <li>Consultation virtuelle</li>
                <li>Financement</li>
            </ul>
            <div className="button-group">
                <NavLink to={"/estimate"} className="button-primary">{t("Book")}</NavLink>
                <NavLink to={"/contactus"} className="button-secondary">{t("Call Now")}</NavLink>
            </div>
        </div>
    </div>
</div>


            </>
        );
    }
}

export default withTranslation()(Slider3);
