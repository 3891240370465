import React, { useState } from 'react';
import GoogleMaps from "simple-react-google-maps"
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import { useHistory } from 'react-router-dom';

var bnrimg = require('./../../images/micropigmentation-capillaire-calvitie-montreal.jpg');

function ContactSuccess() {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const history = useHistory();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        history.push('/contact-success');
        setIsSuccess(true);
        setIsError(false);

        try {
            const response = await fetch('/.netlify/functions/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    subject: 'Contact Form Submission',
                    message: formData.message
                }),
            });
            if (response.ok) {
                console.log('Email sent successfully!');
                setFormData({ name: '', email: '', message: '' });
            } else {
                console.error('Error sending email.');
            }
        } catch (error) {
        setIsSuccess(false);
        setIsError(true);

            console.error('There was an error sending the email.', error);
        }
    };

    return (
        <>
            <Header2 />
            <div className="page-content">
            <Banner title="Professional design: Scalp Micropigmentation Montreal" pagename="Contact us" bgimage={bnrimg.default}/>
            {/* SECTION CONTENTG START */}
            <div className="section-full p-tb80 tm-shortcode-wrap">
            {/* LOCATION BLOCK*/}
            <div className="container">
            {/* TITLE START */}
                            <div className="section-head text-left text-black">
                                <h2 className="text-uppercase font-36">Where to Find us </h2>
                                <div className="wt-separator-outer">
                                    <div className="wt-separator bg-black" />
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="wt-box">
                             
    <div className="alert alert-success">
        <strong><i className="fa fa-thumbs-o-up" /> Success!</strong> Email sent successfully.
    </div>

</div>

                            </div>
                        </div>
                    </div>
                
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };

export default ContactSuccess;
