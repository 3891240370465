import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { withTranslation, useTranslation } from 'react-i18next';





class LatestProjects extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/custom.js');
      
    };
    render() {

const { t } = this.props; 
  const filters = [
            { label: this.props.t('hair-line-label'), filter: ".building-col" },
            { label: this.props.t('calvitie-label'), filter: ".interior-col" },
            { label: this.props.t('spots-label'), filter: ".restaurant-col" }
        ];

const projects = [
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me-4.png'),
        title: this.props.t('hair-line'),
        description: this.props.t('description1'),
        filter: 'building-col',
        link: '/project-detail'
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me-2.png'),
        title: this.props.t('baldness-solution-calvitie'),
        description: this.props.t('description2'),
        filter: 'building-col',
        link: '/project-detail-calvitie'
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me-3.png'),
        title: this.props.t('density-work'),
        description: this.props.t('description3'),
        filter: 'building-col',
        link: '/project-detail-hair-density-work'
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me.png'),
        title: this.props.t('hair-line-density-work'),
        description: this.props.t('description4'),
        filter: 'building-col',
        link: '/project-detail-hair-density-and-hair-line-work'
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me-5.png'),
        title: this.props.t('hair-line'),
        description: this.props.t('description5'),
        filter: 'interior-col',
        link: '/project-detail'
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me-6.png'),
        title: this.props.t('alopecia'),
        description: this.props.t('description6'),
        link: '/project-detail-alopecia'
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-near-me-7.png'),
        title: this.props.t('baldness-solution-calvitie'),
        description: this.props.t('description7'),
        filter: 'restaurant-col',
        link: '/project-detail-calvitie'
    }
]


        const options = {
            loop:false,
            autoplay:true,
            margin:20,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                480:{
                    items:1
                },
                580:{
                    items:2
                },                      
                767:{
                    items:2
                },
                991:{
                    items:3
                },          
                1152:{
                    items:4
                },
                1360:{
                    items:4
                },
                1366:{
                    items:5
                }   
                }
        };
        return (
            <>
                <div id="works" className="section-full p-t90 p-lr80 latest_project-outer square_shape3">
                        {/* TITLE START */}
                        <div className="section-head text-left">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 ">
                                    <h2 className="text-uppercase font-36">{t("explore")}</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <ul className="btn-filter-wrap">
                                        <li className="btn-filter btn-active" data-filter="*">{t("all-project")} </li>
                                        {filters.map((item, index) => (
                                            <li key={index} className="btn-filter" data-filter={item.filter}>{item.label}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <OwlCarousel className="owl-carousel owl-carousel-filter  owl-btn-bottom-left" {...options}>
                                        {projects.map((item, index) => (
                                            <div key={index} className={`${item.filter} item fadingcol`}>
                                                <div className="wt-img-effect ">
                                                    <img src={item.image.default} alt="" />
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation">
                                                            <div className="text-white  font-weight-300 p-a40">
                                                                <h2><NavLink to={`${item.link}`} className="text-white font-20 letter-spacing-1 text-uppercase">{item.title}</NavLink></h2>
                                                                <p>{item.description}</p>
                                                                <NavLink to={`${item.link}`} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">{this.props.t("read-more")}  </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                        </div>
                        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                            <strong>{this.props.t("awesome")} </strong>
                            <span className="text-black">{this.props.t("designs")}  </span>
                        </div>
                    </div>
            </>
        );
    }
};

export default withTranslation()(LatestProjects);