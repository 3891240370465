import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { withTranslation, useTranslation } from 'react-i18next';

var featuredblog = require('./../../images/blog/baldness-camouflage-go-micro.jpg');
var bgimg = require('./../../images/background/ptn-1.png');

class Blog extends React.Component {
    
    render() {

    const blogs = [
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-hair-line-3.png'),
        title: this.props.t('blog-title1'),
        date: this.props.t('blog-date1'),
    },
    {
        image: require('./../../images/Home/Scalp-Micropigmentation-SMP-A-Hopeful-Intervention-Post-Chemotherapy-Treatment.png'),
        title: this.props.t('blog-title2'),
        date: this.props.t('blog-date2'),
    }
]
        return (
            <>
                <div className="section-full p-t140 bg-repeat tm-blog-wrap" style={{ backgroundImage: 'url(' + bgimg.default + ')' }}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head text-left">
                                <h2 className="text-uppercase font-36">{this.props.t('latest-blogs')}</h2>
                                <div className="wt-separator-outer">
                                    <div className="wt-separator bg-black" />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    {blogs.map((item, index) => (
                                        <div className="overlay-wraper bg-no-repeat bg-cover latest-blog-dark-outer p-a20 m-b30" key={index} style={{ backgroundImage: 'url(' + item.image.default + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" />
                                            <div className="latest-blog-dark text-uppercase p-a20">
                                                <h3 className="m-a0"><NavLink to={"/post-video"}><span className="text-white">{item.title}</span></NavLink></h3>
                                                <div className={`${this.props.colorclass} v-date  font-weight-700`}>{item.date}</div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="overlay-wraper bg-no-repeat bg-cover latest-blog-dark-outer2 m-b30" style={{ backgroundImage: 'url(' + featuredblog.default + ')' }}>
                                        <div className="overlay-main bg-black opacity-04" />
                                        <div className="latest-blog-square text-white">
                                            <h2 className="m-t0 m-b10"><NavLink to={"/post-video"}><span className="text-white font-30 text-uppercase">{this.props.t('featured-blog-title')}</span></NavLink></h2>
                                            <p className="font-weight-300">{this.props.t('featured-blog-description')}</p>
                                                <NavLink to={"/post-gallery"} className="letter-spacing-4 font-12 text-white text-uppercase">{this.props.t('read-more')}</NavLink>
                                            <div className={`${this.props.colorclass} v-date  font-weight-700 text-uppercase`}>{this.props.t('featured-blog-date')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                            <strong>{this.props.t('blog')}</strong>
                            <span className="text-black">{this.props.t('post')}</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default withTranslation()(Blog);