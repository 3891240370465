import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';

import { withTranslation, useTranslation } from 'react-i18next';
var bnrimg = require('./../../images/micropigmentation-capillaire-calvitie-montreal.jpg');
var homme = require('./../../images/estimate/clinique-mico-scalp-hairline-styles.png');
var femme = require('./../../images/estimate/female-baldness.png');

class Estimate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                firstName: '',
                familyName: '',
                email: '',
                phone: '',
                gender: '',
                frontLineStyle: '',
                questionsOrComments: ''
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async handleSubmit(e) {
        e.preventDefault();
        const response = await fetch("/.netlify/functions/submit-estimate", {
            method: "POST",
            body: JSON.stringify(this.state.formData),
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.ok) {
            this.props.history.push('/contact-success');
        } else {
            console.error('Error sending email.');
        }
    }

    handleChange(e) {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [e.target.name]: e.target.value
            }
        }));
    }

        render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
    <Banner title={this.props.t('freeEstimateTitle')} pagename={this.props.t('freeEstimate')} bgimage={bnrimg.default} />
    {/* SECTION CONTENTG START */}
    <div className="section-full p-tb80 tm-shortcode-wrap">
        {/* LOCATION BLOCK*/}
        <div className="container">
            {/* TITLE START */}
            <div className="section-head text-left text-black">
                <h2 className="text-uppercase font-36">{this.props.t('whereToFindUs')}</h2>
                <div className="wt-separator-outer">
                    <div className="wt-separator bg-black" />
                </div>
            </div>
            {/* TITLE END */}
            {/* IMAGES */}
            <div className="section-content">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <img src={homme.default} alt={this.props.t('image1Alt')} style={{width: '49%', marginRight: '1%'}} />
                    <img src={femme.default} alt={this.props.t('image2Alt')} style={{width: '49%', marginLeft: '1%'}} />
                </div>
            </div>

                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="wt-box">
                                    <br />
                                    <form className="contact-form cons-contact-form" onSubmit={this.handleSubmit}>
            <div className="contact-one p-a40 p-r150">
                <div className="form-group">
                    <input name="firstName" type="text" required className="form-control" placeholder={this.props.t('firstNamePlaceholder')} onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <input name="familyName" type="text" required className="form-control" placeholder={this.props.t('familyNamePlaceholder')} onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <input name="email" type="text" className="form-control" required placeholder={this.props.t('emailPlaceholder')} onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <input name="phone" type="text" className="form-control" required placeholder={this.props.t('phonePlaceholder')} onChange={this.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="gender">{this.props.t('genderLabel')}</label>
                    <select name="gender" id="gender" className="form-control" onChange={this.handleChange}>
                        <option value="female">{this.props.t('femaleOption')}</option>
                        <option value="male">{this.props.t('maleOption')}</option>
                    </select>
                </div>
                                            <div className="form-group">
    <label htmlFor="frontLineStyle">{this.props.t('frontLineStyleLabel')}</label>
    <select name="frontLineStyle" id="frontLineStyle" className="form-control" onChange={this.handleChange}>
        <option value="1">{this.props.t('option1')}</option>
        <option value="2">{this.props.t('option2')}</option>
        <option value="3">{this.props.t('option3')}</option>
        <option value="4">{this.props.t('option4')}</option>
        <option value="5">{this.props.t('option5')}</option>
        <option value="6">{this.props.t('option6')}</option>
        <option value="7">{this.props.t('option7')}</option>
        <option value="I-1">{this.props.t('optionI1')}</option>
        <option value="I-2">{this.props.t('optionI2')}</option>
        <option value="I-3">{this.props.t('optionI3')}</option>
        <option value="I-4">{this.props.t('optionI4')}</option>
        <option value="II-1">{this.props.t('optionII1')}</option>
        <option value="II-2">{this.props.t('optionII2')}</option>
        <option value="Frontal">{this.props.t('optionFrontal')}</option>
        <option value="III">{this.props.t('optionIII')}</option>
        <option value="Advanced">{this.props.t('optionAdvanced')}</option>
    </select>
</div>

<div className="form-group">
    <textarea name="questionsOrComments" rows={3} className="form-control" required placeholder={this.props.t('questionsOrCommentsPlaceholder')} defaultValue="" onChange={this.handleChange} />
</div>
<button name="submit" type="submit" value="Submit" className="site-button black radius-no text-uppercase">
    <span className="font-12 letter-spacing-5">{this.props.t('submitBtn')}</span>
</button>

                                           <div className="contact-info bg-black text-white p-a30">
    <div className="wt-icon-box-wraper left p-b30">
        <div className="icon-sm"><i className="iconmoon-smartphone-1" /></div>
        <div className="icon-content text-white ">
            <h5 className="m-t0 text-uppercase">{this.props.t('phoneNumberLabel')}</h5>
            <p>{this.props.t('phoneNumber')}</p>
        </div>
    </div>
    <div className="wt-icon-box-wraper left p-b30">
        <div className="icon-sm"><i className="iconmoon-email" /></div>
        <div className="icon-content text-white">
            <h5 className="m-t0 text-uppercase">{this.props.t('emailAddressLabel')}</h5>
            <p>{this.props.t('emailAddress')}</p>
        </div>
    </div>
    <div className="wt-icon-box-wraper left">
        <div className="icon-sm"><i className="iconmoon-travel" /></div>
        <div className="icon-content text-white">
            <h5 className="m-t0 text-uppercase">{this.props.t('addressInfoLabel')}</h5>
            <p>{this.props.t('addressInfo')}</p>
        </div>
    </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    };
};

export default withTranslation()(Estimate);