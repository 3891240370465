import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import RelatedProjects from './../Segments/RelatedProjects';
import BlogComments from './../Segments/BlogComments';

var bnrimg = require('./../../images/micropigmentation-capillaire-calvitie-montreal.jpg');

const images = [
    { image: require('./../../images/Home/ligne-frontale.jpg') },
    { image: require('./../../images/Home/ligne-frontale-2.jpg') },
    { image: require('./../../images/Home/calvitie.jpg') },
    { image: require('./../../images/Home/calvitie-2.jpg') },
]

class PostImage extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content ">
                    <Banner title="A New Solution for Hair Loss: SMP" pagename="Post Image" bgimage={bnrimg.default}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        <div className="container">
                            <div className="max-mid-container">
                                {/* BLOG START */}
                                <div className="blog-post date-style-1 blog-detail text-black">
                                    <div className="wt-post-media clearfix m-b30">
                                        <ul className="grid-post">
                                            {images.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="portfolio-item wt-img-effect zoom-slow">
                                                        <img className="img-responsive" src={item.image.default} alt=""/>
                                                    </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    <div className="wt-post-title ">
                                        <h2 className="post-title"><span className="text-black font-20 letter-spacing-1 font-weight-600">Scalp Micropigmentation (SMP): A New Solution for Hair Loss</span></h2>
                                    </div>
                                    <div className="wt-post-meta ">
                                        <ul>
                                            <li className="post-date"><strong>04 Jan</strong> <span> 2022</span> </li>
                                            <li className="post-comment">5 Comments</li>
                                        </ul>
                                    </div>
                                    <div className="wt-post-text">
                                        <p>The journey of dealing with hair loss can be an emotional roller coaster, impacting one's confidence and self-image. As the world continues to innovate, we have seen the advent of a revolutionary solution in the hair restoration field – Scalp Micropigmentation (SMP), or "traitement de micropigmentation capillaire." This non-surgical, cutting-edge procedure addresses various hair loss conditions, promising to restore not just your hair appearance but also your confidence.</p>

<h3>Understanding Scalp Micropigmentation</h3>

<p>Scalp Micropigmentation is a non-invasive procedure that uses micro-needles to deposit pigment onto the scalp, mimicking the appearance of hair follicles. The SMP technique results in the illusion of a fuller, thicker head of hair. From receding hairlines to bald spots and thinning hair, SMP serves as a potent solution for a wide range of hair loss conditions.</p>

<h3>The Procedure of Scalp Micropigmentation</h3>

<p>Unlike a conventional tattoo, the SMP process requires specialized equipment and in-depth understanding of hair follicle replication. A skilled practitioner will use micro-needles to insert tiny deposits of pigment into the scalp, replicating the natural look of real hair follicles. The color of the pigment is carefully matched to the individual's existing hair color and skin tone to ensure a seamless, natural-looking result.</p>

<h3>Why Choose Scalp Micropigmentation as a Hair Loss Solution?</h3>

<p>SMP offers numerous benefits that make it a standout solution for hair loss:

Instant Results: Unlike other hair restoration treatments that may take months or even years to show results, SMP provides instant results. After the first session, clients can already see a significant change.

Low Maintenance: Once the treatment is completed, no extensive upkeep is necessary. You don't have to purchase special shampoos or hair growth supplements.

Long-Lasting: The effects of SMP typically last between four to six years. Even as it begins to fade, a quick touch-up can restore SMP's look.

Cost-Effective: While the upfront cost of SMP might seem high, it is a one-time investment. Compared to ongoing costs associated with hair transplants or medicinal treatments, SMP could end up being more affordable in the long run.

Safe: SMP is a non-surgical procedure, so it doesn't involve the risk factors associated with surgery. Additionally, the pigments used in SMP are generally safe and hypoallergenic.</p>

<h3>Conclusion</h3>

<p>Scalp Micropigmentation or "traitement de micropigmentation capillaire" is more than just a cosmetic procedure – it's a life-changing treatment that could be the answer to many people's hair loss struggles. By providing an affordable, long-lasting, and realistic hair loss solution, SMP is undoubtedly transforming the hair restoration industry and boosting the confidence of thousands worldwide.</p>
                                        <blockquote>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 tm-qt-img">
                                                    <img src={require("./../../images/Home/best-version-of-yourself.jpg").default} alt=""/>
                                                </div>
                                                <div className="col-md-8 col-sm-6">
                                                    Scalp Micropigmentation is not merely a cosmetic procedure; it's a life-altering solution. It's about reclaiming your confidence and self-image by presenting to the world the best version of yourself.
                                                    <div className="p-t15"><p> – Dr. William Rassman, Renowned Hair Restoration Surgeon.</p></div>
                                                </div>
                                            </div>
                                        </blockquote>
                                        <p>If you've been searching for a definitive solution to hair loss, look no further. Scalp Micropigmentation offers a cutting-edge, long-lasting, and effective treatment that's transforming lives worldwide. It's time to turn the page and start a new chapter in your life – one filled with confidence and self-assurance. Don't let hair loss hold you back any longer. Schedule a consultation with a Scalp Micropigmentation specialist today and embark on the journey to restore not only your hair but your overall self-esteem and quality of life. Start your SMP journey now! Contact Us Today: +1 (438) 228-5131
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 col-sm-6">
                                            <div className="widget bg-white  widget_tag_cloud">
                                                <h4 className="tagcloud text-uppercase">Tags</h4>
                                                <div className="tagcloud">
                                                    <NavLink to={"/aboutus"}>Scalp Micropigmentation</NavLink>
                                                    <NavLink to={"/aboutus"}>Hair Restoration</NavLink>
                                                    <NavLink to={"/aboutus"}>Non-surgical Treatment</NavLink>
                                                    <NavLink to={"/aboutus"}>Hair Loss Solution</NavLink>
                                                    <NavLink to={"/aboutus"}>SMP Procedure</NavLink>
                                                    <NavLink to={"/aboutus"}>Cost-effective</NavLink>
                                                    <NavLink to={"/aboutus"}>Baldness Solution</NavLink>
                                                    <NavLink to={"/aboutus"}>Hairline Enhancement</NavLink>
                                                    <NavLink to={"/aboutus"}>Self-Esteem Boost</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="wt-box">
                                                <div className="row  p-lr15">
                                                    <h4 className="tagcloud text-uppercase">Share this Post:</h4>
                                                    <div className="widget_social_inks">
                                                        <ul className="social-icons social-md social-square social-dark m-b0">
                                                            <li><a target="_blank" href="https://www.facebook.com" className="fa fa-facebook" /></li>
                                                            <li><a target="_blank" href="https://twitter.com" className="fa fa-twitter" /></li>
                                                            <li><a target="_blank" href="https://in.linkedin.com" className="fa fa-linkedin" /></li>
                                                            <li><a target="_blank" href="https://rss.com" className="fa fa-rss" /></li>
                                                            <li><a target="_blank" href="https://www.youtube.com/" className="fa fa-youtube" /></li>
                                                            <li><a target="_blank" href="https://www.instagram.com/" className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <RelatedProjects/>
                                {/*<BlogComments/>*/}
                                
                                
                                {/* BLOG END */}
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default PostImage;