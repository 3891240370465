import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';
const images = [
    require('./../../images/about/calvitie-solution-temporaire-scalp-micropigmentation-montreal.jpg'),
    require('./../../images/about/Ligne-frontale-scalp-micropigmentation-montreal.jpg'),
    require('./../../images/about/fix-your-frontal-hair-line-with-scalp-micropigmentation.jpg'),
    require('./../../images/about/beard-micropigmentation.jpg'),
    require('./../../images/about/density-work-hide-head-holes-with-scalp-micropigmentation-montreal.jpg'),
    require('./../../images/about/virtual-home-consultation-scalp-micropigmentation.jpg')
]




class Services extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {

  const t = this.props.t;

        const servicesleft = [
            {
                counter: 0,
                title: t('servicesLeftTitle1'),
                description: t('servicesLeftDescription1'),
            },
            {
                counter: 1,
                title: t('servicesLeftTitle2'),
                description: t('servicesLeftDescription2'),
            },
            {
                counter: 2,
                title: t('servicesLeftTitle3'),
                description: t('servicesLeftDescription3'),
            }
        ]

        const servicesright = [
            {
                counter: 3,
                title: t('servicesRightTitle1'),
                description: t('servicesRightDescription1'),
            },
            {
                counter: 4,
                title: t('servicesRightTitle2'),
                description: t('servicesRightDescription2'),
            },
            {
                counter: 5,
                title: t('servicesRightTitle3'),
                description: t('servicesRightDescription3'),
            }
        ]

        return (
    <>
        <div className="section-full bg-black p-t90 p-b30 square_shape1 tm-service2-wrap">
            <div className="container">
                {/* TITLE START */}
                <div className="section-head text-left text-white">
                    <h2 className="text-uppercase font-36">{t('headerTitle')}</h2>
                    <div className="wt-separator-outer">
                        <div className="wt-separator bg-white" />
                    </div>
                </div>
                {/* TITLE END */}
                <div className="section-content circle-block-outer" data-toggle="tab-hover">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 m-b30">
                            {servicesleft.map((item, index) => (
                                <div className="wt-icon-box-wraper right p-a20 m-b50 text-white" data-target={`#tab${item.counter}`} data-toggle="tab" key={index}>
                                    <div className="icon-content">
                                        <h4 className="wt-tilte text-uppercase">{item.title}</h4>
                                        <p className="description-text">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-md-4 col-sm-12 m-b30">
                            <div className="circle-content-pic tab-content">
                                {images.map((item, index) => (
                                    <div className={index === 0 ? `tab-pane active` : `tab-pane`} id={`tab${index}`} key={index}>
                                        <div className="wt-box">
                                            <div className="wt-media text-primary m-b20">
                                                <img src={item.default} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 m-b30">
                            {servicesright.map((item, index) => (
                                <div className="wt-icon-box-wraper right p-a20 m-b50 text-white" data-target={`#tab${item.counter}`} data-toggle="tab" key={index}>
                                    <div className="icon-content">
                                        <h4 className="wt-tilte text-uppercase">{item.title}</h4>
                                        <p className="description-text">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

    }
};
export default withTranslation()(Services);