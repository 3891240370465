import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Navigation() {
    const { t } = useTranslation();

    return (
        <>
            <ul className="nav navbar-nav">
                <li className="active">
                    <NavLink to={"/"}>{t("home")}</NavLink>
                </li>
                <li>
                    <NavLink to={"/aboutus"}>{t("aboutUs")}</NavLink>
                </li>
                <li>
                    <NavLink to={"/work-masonary"}>{t("ourWork")}</NavLink>
                </li>
                <li>
                    <NavLink to={"#"}>{t("blog")}</NavLink>
                    <ul className="sub-menu v-sub-menu">
                        <li><NavLink to={"/post-image"}>{t("blogPost1")}</NavLink></li>
                        <li><NavLink to={"/post-gallery"}>{t("blogPost2")}</NavLink></li>
                        <li><NavLink to={"/post-video"}>{t("blogPost3")}</NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to={"/estimate"}>{t("freeEstimate")}</NavLink>
                </li>
                <li>
                    <NavLink to={"/contactus"}>{t("contactUs")}</NavLink>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
