import React from 'react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';

import { withTranslation, useTranslation } from 'react-i18next';
var bgimg = require('./../../images/background/d49433.png');

class Statistics extends React.Component {
    
    render() {
        
        return (
            <>
                <div className="section-full p-t90 p-b50 overlay-wraper bg-top-center bg-parallax tm-facts-wrap" data-stellar-background-ratio="0.5" style={{ backgroundImage: "url(" + bgimg.default + ")" }}>
                        <div className="overlay-main opacity-08 bg-black" />
                        <div className="container ">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="some-facts">
                                        <div className="text-white">
                                            <span className="font-40 font-weight-300  text-uppercase">{this.props.t('some')}</span>
                                            <h2 className="font-50  text-uppercase">
                                                {this.props.t('faq')}
                                            </h2>
                                            <p className="font-18 font-weight-300">{this.props.t('faq-description')}</p>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="col-md-6 col-sm-12">
                                    <div className="m-b0">
                                      
                                        {/* ACCORDIAN  BG DARK */}
                                        <div className="section-content p-b0">
                                            <div className="wt-accordion acc-bg-dark" id="accordion9">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h6 className="acod-title text-uppercase">
                                                            <a data-toggle="collapse" href="#collapseOne9" data-parent="#accordion9"><i className="fa fa-globe" />
                                                                {this.props.t('question1')}
                                                                <span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div id="collapseOne9" className="acod-body collapse in">
                                                        <div className="acod-content p-tb15">{this.props.t('answer1')}</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h6 className="acod-title text-uppercase">
                                                            <a data-toggle="collapse" href="#collapseTwo9" className="collapsed" data-parent="#accordion9"><i className="fa fa-photo" />
                                                                {this.props.t('question2')}
                                                                <span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div id="collapseTwo9" className="acod-body collapse">
                                                        <div className="acod-content p-tb15">{this.props.t('answer2')}</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h6 className="acod-title text-uppercase">
                                                            <a data-toggle="collapse" href="#collapseThree9" className="collapsed" data-parent="#accordion9"><i className="fa fa-cog" />
                                                                {this.props.t('question3')}
                                                                <span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div id="collapseThree9" className="acod-body collapse">
                                                        <div className="acod-content p-tb15">{this.props.t('answer3')}</div>
                                                    </div>

                                                </div>
                                                

                                        {/* TITLE START */}
                                     
                                        </div>
                                        {/* TITLE END */}
                                        <div className="section-content">

                                            <a href="tel:+14385421696" className="site-button outline  white button-app m-r15 m-b15"><strong className="text-center">(438) 228-5131</strong>  <strong className="text-center">{this.props.t('book-now')}</strong></a>
                                            
                                      
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default withTranslation()(Statistics);