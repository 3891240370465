import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { withTranslation, useTranslation } from 'react-i18next';

var img1 = require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-hair-line-pour-femme.png');
var bgimg = require('./../../images/background/ptn-1.png');

class WhoWeAre extends React.Component {
  
    render() {

    const projects = [
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-hair-line.png'),
        title: this.props.t('projectTitle1'),
        description: this.props.t('projectDescription1'),
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-hair-line-2.png'),
        title: this.props.t('projectTitle2'),
        description: this.props.t('projectDescription2'),
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-hair-line-3.png'),
        title: this.props.t('projectTitle3'),
        description: this.props.t('projectDescription3'),
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-hair-line-4.png'),
        title: this.props.t('projectTitle4'),
        description: this.props.t('projectDescription4'),
    },
    {
        image: require('./../../images/go-micro-smp-micropigmentation-capillaire-a-pierrefonds-hair-line-5.png'),
        title: this.props.t('projectTitle5'),
        description: this.props.t('projectDescription5'),
    }
]



        const options = {
            loop:true,
            autoplay:true,
            margin:0,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                480:{
                    items:1
                },          
                767:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
        };
        return (
    <>
        <div id="about" className="section-full p-t140 bg-repeat tm-whoweare-wrap" style={{ backgroundImage: 'url(' + bgimg.default + ')' }}>
            <div className="container-fluid">
                <div className="section-content">
                    <div className="row">
                        <div className="col-lg-6 col-md-5 col-sm-12">
                            <div className="wt-left-part">
                                <div className="text-uppercase text-black">
                                    <span className="font-30 font-weight-300">{this.props.t('whoWeAreTitle')}</span>
                                    <h2 className="font-40">
                                        {this.props.t('redefiningBeauty')}
                                    </h2>
                                    <p>{this.props.t('aboutDescription')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7 col-sm-12">
                            <div className="m-carousel-2">
                                <OwlCarousel className="owl-carousel carousel-hover home-carousel-2 owl-btn-vertical-center" {...options}>
                                    {projects.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="wt-box">
                                                <div className="ow-img wt-carousel-block gradi-black">
                                                    <img src={item.image.default} alt="" />
                                                    <div className="p-a50 wt-carousel-info text-white">
                                                        <div className="carousel-line">
                                                            <h2 className="font-28 font-weight-400 m-b10">{this.props.t(`projectTitle${index + 1}`)}</h2>
                                                            <p className="m-b0">{this.props.t(`projectDescription${index + 1}`)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                                <div className="carousel-bg-img">
                                    <img src={img1.default} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="hilite-title p-lr20 m-tb20 text-left text-uppercase bdr-gray bdr-left">
                        <strong>{this.props.t('projectsLabel')}</strong>
                        <span className="text-black">{this.props.t('completedLabel')}</span>
                    </div>
                </div>
            </div>
        </div>
    </>
);

    }
};

export default withTranslation()(WhoWeAre);