import './processMock';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en.json'; // Replace 'en.json' with your English translations file
import esTranslation from './locales/fr.json'; // Replace 'es.json' with your Spanish translations file

i18n
  .use(LanguageDetector)
  .init({
    fallbackLng: 'fr', // Fallback language if the user's preferred language is not available
    resources: {
      en: { translation: enTranslation },
      fr: { translation: esTranslation },
      // Add more languages and their translations as needed
    },
  });

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);

reportWebVitals();