import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { withTranslation, useTranslation } from 'react-i18next';
const members = [
    {
        image: require('./../../images/Home/google-reviewed.jpeg'),
        fullname: 'Winona Faith',
        position: 'Google Customer',
        description: 'My experience with micro pigmentation were above and beyond! Pierre has an eye for detail, expertise and skills that you’re looking for in an artist. He is very attentive to the quality of his work! After only 2 sessions, I’ve noticed a major change in not only how I look but how I feel! I would definitely recommend anyone with thinning hair or hair loss to consult with him. I couldn’t be happier with my results!!',
    },
    {
        image: require('./../../images/Home/google-reviewed.jpeg'),
        fullname: 'da tint',
        position: 'Google Customer',
        description: "Sufis la fibre capillaire  qui part apres une douche ! Quand on y pense au coût a court terme , c'est clair qu'on est cheap ! Là , lui Pierre ma fait réaliser et comprendre que juste faire le switch de cette mentalité et la changer pour une mentalité à long terme , tout va mieux aller ! Que ce soit Maison, Femme, Esthétique de mon corp, Job, etc ! J'ai opté pour la solution a long terme qui est la Micropigmentation Capillaire! Jai hate de la publier mon nouveau toupette sur mes réseaux sociaux. Merci mille fois Pierre.",
    },
    {
        image: require('./../../images/Home/google-reviewed.jpeg'),
        fullname: 'Jimmy Khai',
        position: 'Google Customer',
        description: 'Après longue réflexion j\'ai décidé de faire le traitement. Aucun regret, j\'ai l\'impression d\'avoir pris 10 ans de jeunesse. Pierre sait donner son avis professionnel tout en prenant compte de vos préférences personnelles. Je recommande donc le traitement micro capillaire à quiconque qui souhaite se donner un nouveau look.',
    },
    {
        image: require('./../../images/Home/google-reviewed.jpeg'),
        fullname: 'Oumar Adoum',
        position: 'Google Customer',
        description: 'the best experience!! very professional, super service and result respect👌👍',
    },
    {
        image: require('./../../images/Home/google-reviewed.jpeg'),
        fullname: 'Réginald Gratia',
        position: 'Google Customer',
        description: "JP est un vrai professionnel. Il est à l'écoute de ses clients, soucieux et s'assure que tous sont de l'expérience satisfait car à la fin de journée on porte son travail. J'étais réticent au processus mais je vois une différence avant et après et j'apprécie la  confiance de plus ça m'a donné. Je recommande.",
    },
    {
        image: require('./../../images/Home/google-reviewed.jpeg'),
        fullname: 'Soledad Bessette',
        position: 'Google Customer',
        description: 'Très bon service et du perfectionniste on point avec JP merci 🙏',
    }
]

var bgimg = require('./../../images/background/ptn-1.png');

class Testimonials extends React.Component {

    render() {
    const { t } = this.props; 
        const options = {
            loop: true,
            autoplay: false,
            margin: 80,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 2
                }
            }
        };
        return (
            <>
                <div className="section-full p-t140 bg-repeat tm-testimonial-wrap" style={{ backgroundImage: 'url(' + bgimg.default + ')' }}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head text-left">
                                <h2 className="text-uppercase font-36">{t("testimonials")}</h2>
                                <div className="wt-separator-outer">
                                    <div className="wt-separator bg-black" />
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL 4 START ON BACKGROUND */}
                            <div className="section-content">
                                <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                                    {members.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="testimonial-6">
                                                <div className="testimonial-pic-block">
                                                    <div className="testimonial-pic">
                                                        <img src={item.image.default} alt="" width={132} height={132} />
                                                    </div>
                                                </div>
                                                <div className="testimonial-text clearfix bg-white">
                                                    <div className="testimonial-detail clearfix">
                                                        <strong className="testimonial-name">{item.fullname}</strong>
                                                        <span className="testimonial-position p-t0">{item.position}</span>
                                                    </div>
                                                    <div className="testimonial-paragraph text-black p-t15">
                                                        <span className="fa fa-quote-left" />
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                </OwlCarousel>

                            </div>
                        </div>
                    </div>
                    <div className="container">
    <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
        <strong>{this.props.t('clientLabel')}</strong>
        <span className="text-black">{this.props.t('saysLabel')}</span>
    </div>
</div>

                </div>
            </>
        );
    }
};

export default withTranslation()(Testimonials);