import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { withTranslation, useTranslation } from 'react-i18next';

var img1 = require('./../../images/our-team5/scalp-micropigmentation-west-island-montreal-pierrefonds.jpg');



class TeamMembers extends React.Component {
    
    render() {

 const members = [
    {
        image: require('./../../images/baldness-solution-micro-pigmentation-hair-tattoo.png'),
        fullname: this.props.t('calvitie-label'),
        position: this.props.t('service-label'),
    },
    {
        image: require('./../../images/alopecie-hair-tattoo-montreal.png'),
        fullname: this.props.t('alopecie-label'),
        position: this.props.t('service-label'),
    },
    {
        image: require('./../../images/front-line-hair-tattoo-montreal.png'),
        fullname: this.props.t('frontal-hairline-label'),
        position: this.props.t('service-label'),
    },
    {
        image: require('./../../images/scar-camouflage-hair-tattoo-montreal.png'),
        fullname: this.props.t('scar-camouflage-label'),
        position: this.props.t('service-label'),
    }
];
        
        return (
            <>
                <div className="section-full bg-gray square_shape3 tm-expert-wrap">
                        <div className="container-fluid">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 bg-black square_shape1 square_shape2">
                                        <div className="wt-left-part m-experts p-t90">
                                            {/* TITLE START */}
                                            <div className="section-head text-left text-white">
                                                <h2 className="text-uppercase font-36">{this.props.t('our-services')}</h2>
                                                <div className="wt-separator-outer">
                                                    <div className="wt-separator bg-white" />
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="wt-team-six large-pic">
                                                <div className="wt-team-media wt-thum-bx">
                                                    <img src={img1.default} alt=""/>
                                                </div>
                                                <div className="wt-team-info text-center p-lr10 p-tb20 text-white">
                                                    <h2 className="wt-team-title text-uppercase"><span className="text-white font-32 font-weight-500">Pierre Castro </span></h2>
                                                    <p className="font-22">{this.props.t('founder')}</p>
                                                    <ul className="social-icons social-md social-square social-dark white-border has-bg">
                                                        <li><a target="_blank" href="https://www.facebook.com/gomicrooSMP" className="fa fa-facebook" /></li>
                                                        <li><a target="_blank" href="https://t.snapchat.com/EcjawztZ" className="fa fa-snapchat" /></li>
                                                        <li><a target="_blank" href="https://www.instagram.com/scalpgomicro.smp/" className="fa fa-instagram" /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="hilite-title p-lr20 m-tb20 text-left text-uppercase bdr-gray bdr-left">
                                                <strong className="text-gray">{this.props.t('experts')}</strong>
                                                <span className="text-white">{this.props.t('team-members')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="wt-right-part team-outer">
                                            <div className="row">
                                                {members.map((item, index) => (
                                                    <div className="col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-tb15" key={index}>
                                                    <div className="wt-team-six bg-white">
                                                        <div className="wt-team-media wt-thum-bx wt-img-overlay1">
                                                            <img src={item.image.default} alt=""/>
                                                            <div className="overlay-bx">
                                                                <div className="overlay-icon">
                                                                    <ul className="social-icons social-square social-dark">
                                                                        <li><a target="_blank" href="https://www.facebook.com" className="fa fa-facebook" /></li>
                                                                        <li><a target="_blank" href="https://www.twitter.com" className="fa fa-twitter" /></li>
                                                                        <li><a target="_blank" href="https://in.linkedin.com" className="fa fa-linkedin" /></li>
                                                                        <li><a target="_blank" href="https://www.youtube.com" className="fa fa-youtube" /></li>
                                                                        <li><a target="_blank" href="https://www.instagram.com" className="fa fa-instagram" /></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wt-team-info text-center bg-white p-lr10 p-tb20">
                                                            <h5 className="wt-team-title text-uppercase m-a0"><span>{item.fullname}</span>
                                                                
                                                                {/**/}
                                                            </h5>
                                                            <p className="m-b0">{item.position}</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};
export default withTranslation()(TeamMembers);