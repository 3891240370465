import React from 'react';
import { NavLink } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import { withTranslation, useTranslation } from 'react-i18next';


var bnrimg = require('./../../images/micropigmentation-capillaire-calvitie-montreal.jpg');

class WorkMasonary extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/custom.js');
      
    };
    render() {

 const projects = [
    {
        image: require('./../../images/our-work/Scalp-micropigmentation-for-thinning-hair.jpg'),
        title: this.props.t('didYouKnowSMPForScars'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/best-scalp-micropigmentation-clinic-pierrefonds-montreal-770-420.jpg'),
        title: this.props.t('didYouKnowSMPForDensity'),
        colwidth: 'col-lg-6'
    },
    {
        image: require('./../../images/our-work/alopecie-solution-scalp-micropigmentation-a-montreal-et-pierrefonds.jpg'),
        title: this.props.t('didYouKnowSMPForNaturalLook'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/beard-micropigmentation.jpg'),
        title: this.props.t('didYouKnowSMPLastsYears'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/calvitie-solution-temporaire-scalp-micropigmentation-montreal.jpg'),
        title: this.props.t('didYouKnowSMPQuickProcedure'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/density-work-hide-head-holes-with-scalp-micropigmentation-montreal.jpg'),
        title: this.props.t('didYouKnowSMPSafeForSensitiveSkin'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/a-new-solution-for-hair-loss-scalp-micropigmentation.jpg'),
        title: this.props.t('didYouKnowSMPHairlineStyles'),
        colwidth: 'col-lg-6'
    },
    {
        image: require('./../../images/our-work/fix-your-frontal-hair-line-with-scalp-micropigmentation.jpg'),
        title: this.props.t('didYouKnowSMPForMenAndWomen'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/Ligne-frontale-scalp-micropigmentation-montreal.jpg'),
        title: this.props.t('didYouKnowSMPMinimalMaintenance'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/Scalp-micropigmentation-before-and-after.jpg'),
        title: this.props.t('didYouKnowSMPForShavedLook'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/Scalp-micropigmentation-reviews.jpg'),
        title: this.props.t('didYouKnowSMPCostEffective'),
        colwidth: 'col-lg-3'
    },
    {
        image: require('./../../images/our-work/virtual-home-consultation-scalp-micropigmentation.jpg'),
        title: this.props.t('didYouKnowSMPForAlopecia'),
        colwidth: 'col-lg-3'
    }
]

        return (
            <>
                <Header2 />
<div className="page-content">
    <Banner title={this.props.t('ourWorkTitle')} pagename="Work masonry" bgimage={bnrimg.default}/>
    
    {/* SECTION CONTENT */}
    <div className="section-full p-tb90 tm-work-wrap">
        {/* GALLERY CONTENT START */}
        <div className="container-fluid">
            <div className="portfolio-wrap mfp-gallery work-masonry clearfix">
                <div className="row">
                {projects.map((item, index) => (
                        <div key={index} className={`${item.colwidth} masonry-item col-md-6 col-sm-6 m-b30`}>
                        <div className="wt-img-effect ">
                            <img src={item.image.default} alt="" />
                                <div className="overlay-bx-2 ">
                                    <div className="line-amiation  p-a40">
                                        <div className="text-white">
                                            <h2><NavLink to={"/aboutus"} className="text-white font-24 font-weight-300">{item.title}</NavLink></h2>
                                            <NavLink to={"/aboutus"} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">{this.props.t('readMore')}</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    
                </div>
            </div>
        </div>
        {/* GALLERY CONTENT END */}
         <div className="text-center mt-5">
      
        <NavLink to={"/estimate"} className="btn-half site-button button-lg m-b15"><span> {this.props.t('requestFreeEstimate')}</span><em /></NavLink>
    </div>
    </div>
    {/* SECTION CONTENT END */}
   
</div>

<Footer />

            </>
        );
    };
};

export default withTranslation()(WorkMasonary);