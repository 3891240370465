import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';
var img1 = require('./../../images/about/a-new-solution-for-hair-loss-scalp-micropigmentation.jpg');
var img2 = require('./../../images/about/how-to-camouflage-baldness-in-2023.jpg');
var img3 = require('./../../images/about/best-scalp-micropigmentation-clinic-pierrefonds-montreal.jpg');
var img4 = require('./../../images/gallery/pic5.jpg');

class Story extends React.Component {
    
    render() {
        
        return (
            <>
                <div className="section-full bg-gray p-tb90 square_shape1 tw-our-story-wrap">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head text-left text-black">
                            <h2 className="text-uppercase font-36">{this.props.t('ourMicropigmentationServicesTitle')}</h2>
                            <div className="wt-separator-outer">
                                <div className="wt-separator bg-black" />
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content our-story">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="wt-media our-story-pic">
                                        <img src={img1.default} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="wt-box our-story-detail">
                                        <h3 className="text-uppercase">{this.props.t('dateFeb2023')}</h3>
                                        <h2 className="text-uppercase">{this.props.t('newSolutionTitle')}</h2>
                                        <p>{this.props.t('newSolutionDescription')}</p>
                                    </div>
                                </div>
                            </div>
                               <div className="row">
    <div className="col-md-6 col-sm-12">
        <div className="wt-box our-story-detail">
            <h3 className="text-uppercase">{this.props.t('dateMar2023')}</h3>
            <h2 className="text-uppercase">{this.props.t('camouflageBaldnessTitle')}</h2>
            <p>{this.props.t('camouflageBaldnessDescription')}</p>
        </div>
    </div>
    <div className="col-md-6 col-sm-12">
        <div className="wt-media our-story-pic">
            <img src={img2.default} alt="" />
        </div>
    </div>
</div>
<div className="row">
    <div className="col-md-6 col-sm-12">
        <div className="wt-media our-story-pic">
            <img src={img3.default} alt="" />
        </div>
    </div>
    <div className="col-md-6 col-sm-12">
        <div className="wt-box our-story-detail">
            <h3 className="text-uppercase">{this.props.t('date2015_16')}</h3>
            <h2 className="text-uppercase">{this.props.t('postChemoTreatmentTitle')}</h2>
            <p>{this.props.t('postChemoTreatmentDescription')}</p>
        </div>
    </div>
</div>
                                
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default withTranslation()(Story);